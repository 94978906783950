body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #EBEBEB;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* Migrating from Bootstrap 4.x to 5.x */
.form-group { margin-bottom: 1rem; }
.form-select { font-size: 14px; }
.nav-tabs .nav-link { font-weight: 700 !important; }
.table>:not(:first-child) { border-top: 0px solid transparent; }
.table-wrap table { width: 100%; }
.modal-title { color: #000 !important; font-weight: 600 !important; }

/*logo and title "RI.Insights"*/
.header {
	background-color: #ff7300;
	color: #ffffff;
	padding: 9px;
	padding-left: 150px;
}
/*Login Page*/
.login-header {
	background-color: #ff7300;
	color: #ffffff;
	font-size: 28px;
	padding: 15px;
	padding-left: 10px;
}
.drop-zone {
	min-height: 100px;
	width: 100%;
	border: 1px dotted;
	text-align: center;
}
.selected-files {
	position: relative;
	height: 300px;
	width: 50%;
	border: 1px solid grey;
	overflow: auto;
	padding: 10px;
	margin: auto;
}
.selected-files ul {
	max-height: 200px;
	overflow: auto;
}
.selected-files .buttons {
	width: calc(100% - 20px);
	position: absolute;
	bottom: 0;
}
.selected-files .upload-selected {
	float: right;
	right: 0;
}
.error-msg {
	color: #dc3545;
	margin: 0;
}
.error-msg p { margin: 0; font-size: 13px; }
.disclaimer-error-msg {
	color: #dc3545;
	margin: auto;
	margin-top: 5px;
}
.input-error-msg {
	color: #dc3545;
	margin: auto;
	margin-top: 5px;
	margin-bottom: -10px;
}
.top-error-msg {
	color: #dc3545;
}
.success-msg {
	color: green;
}
.sub-heading {
	margin: auto;
	padding-top: 10px 0px;
	line-height: 2;
	width: 35%;
	font-size: 24px;
	margin-top: 30px;
}
.btn-primary {
	background-color: #73BC51 !important;
	font-size: 15px !important;
	color: #ffffff !important;
	border: 0 !important;
	height: auto !important;
	padding: 8px 20px !important;
}
.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
	background-color: #fff !important;
	padding: 8px 20px !important;
	font-size: 15px !important;
	color: #73BC51 !important;
	height: auto !important;
	border: 1px solid #73BC51 !important;
}
.input-file {
	margin: auto;
}
/* New Loader Start */
.loader-wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 100;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loader {
	display: inline-block;
	width: 30px;
	height: 30px;
	position: relative;
	border: 4px solid rgb(255, 153, 0);
	animation: loader 2s infinite ease;
}
.loader-inner {
	vertical-align: top;
	display: inline-block;
	width: 100%;
	background-color: rgb(255, 153, 0);
	animation: loader-inner 2s infinite ease-in;
}
@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(180deg);
	}
	50% {
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes loader-inner {
	0% {
		height: 0%;
	}
	25% {
		height: 0%;
	}
	50% {
		height: 100%;
	}
	75% {
		height: 100%;
	}
	100% {
		height: 0%;
	}
}
/* New Loader End */
.loading-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	background: #0e0e0e;
	opacity: 0.3;
	color: #ffffff;
	display: table;
	height: 100vh;
	z-index: 1;
}
.loading-overlay span {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}
.loading-overlay-upload {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	background: #0e0e0e;
	opacity: 0.3;
	color: #ffffff;
	display: table;
	height: 100% !important;
	z-index: 3 !important;
}
.loading-overlay-upload span {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	padding-top: 20px;
}
.modal-backdrop {
	z-index: 1050;
}
.disable-link {
	pointer-events: none
}
/*File upload Page*/
.upload-status {
	margin-top: 20px;
}
.progress-bar {
	background-color: #73BC51;
}
/* login page css start*/
.form-signin {
	position: relative;
}
#newUser .form-signin {
	margin: 0 0 0 47px;
}
.form-signin nav {
	margin-top: 28px;
}
.form-signin nav a:first-child {
	margin-right: 53px;
}
.login_content {
	width: 20%;
	padding: 0;
	margin: auto;
	background-color: #fff;
}
label {
	font-size: 16px;
	font-weight: 500;
	color: #5a595b;
}
#newUser .login_content {
	width: 100%;
}
.login_content ul {
	margin: 0;
	padding: 0;
	width: 100%;
	overflow: hidden;
}
.login_content ul li {
	float: left;
	list-style: none;
	width: 49%;
	margin-left: 3px;
	min-height: 112px;
}
.login_content ul li input {
	width: 305px;
}
/*login page css end*/
/* side menu css */
.sidenav {
	background: #ddd;
	width: 239px;
	background-color: #ffffff;
	transition: 0.5s;
	padding-top: 44px;
	border-right: 1px solid #E0E0E0;
}
nav a {
	padding: 8px 8px 8px 20px;
	text-decoration: none;
	font-size: 16px;
	font-weight: bold;
	color: #414042;
	display: block;
	transition: 0.3s;
}
nav a:hover {
	text-decoration: none;
}
nav a:active {
	color: #ffffff;
	text-decoration: none;
}
.sidenav ul {
	margin: 0;
	padding: 0;
}
.sidenav ul li {
	position: relative;
	list-style: none;
	min-height: 60px;
	padding: 0;
	margin: 0;
}
.sidenav ul li a {
	margin-left: 55px;
	padding-top: 15px;
	padding-bottom: 18px;
	position: absolute;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140.62%;
	width: 150px;
	height: 22px;
	left: 57px;
	top: 5px;
	color: #000;
	z-index: 999;
}
.sidenav ul li img {
	position: absolute;
	left: 21px;
	top: 6px;
}
.sidenav ul li.slctd {
	background: #73BC51;
	color: #ffffff;
}
.slctd a {
	color: #fff !important;
}
/* .sidenav ul li:first-child {
border-top: 2px solid #838384;
} */
.sidenav ul li.bbottom {
	border-bottom: 2px solid #838384;
}
.sidenav ul li span {
	position: absolute;
	left: 30px;
	top: 15px;
}
.help-icon {
	margin-left: 28px;
	margin-top: 12px;
}
/* side menu css ends */
/* header css starts */
.logo {
	font-size: 28px;
	padding-top: 3px;
}
.logo-image {
	margin-top: -5px;
	height: 22px;
	width: 40px;
}
.user-details span {
	font-weight: bold;
	display: block;
	line-height: 14px;
}
.top-btns {
	border-left: 2px solid #ff9543;
	padding-left: 30px;
	margin-right: 10px;
}
.top-btns a {
	margin-right: 30px;
	position: relative;
	top: 15px;
	text-decoration: none;
}
.top-btns a:last-child {
	margin-right: 0;
}
.navbar-nav {
	flex-direction: row !important;
}
.navbar-nav li span.badge-nav {
	display: inline !important;
	color: #73BC51;
	position: relative;
	top: -15px;
	left: -11px;
	font-size: 11px;
}
.profile-text {
	padding: 8px 4px 8px 4px;
}
#profileImg img {
	width: 40px;
	border-radius: 50%;
}
/* header css ends */
/** File upload browse button changes **/
div.fileinputs {
	position: relative;
}
div.sudofile {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
	width: 100%;
}
input.file {
	position: relative;
	opacity: 0;
	z-index: 2;
	cursor: pointer;
}
.fileinputs .input-file {
	text-overflow: ellipsis;
}
button.browse {
	position: absolute;
	right: 0;
	height: 100%;
	background-color: grey !important;
	top: 0;
	margin-right: -85px;
}
.form-container {
	display: flex;
	flex-direction: column;
	margin: 0 5% 20px;
}
.form-label {
	margin-top: 5px;
}
.facility-label {
	margin-top: 5px;
}
.btns-style {
	margin-top: 20px;
}
.btn-cancel {
	margin-left: 20px;
}
.mrgtop-20 {
	margin-top: 20px;
}
.mrgtop-60 {
	margin-top: 60px;
}
.chk-label {
	display: block;
	padding-left: 15px;
	text-indent: -15px;
	font-weight: normal;
}
.chk-input {
	width: 13px;
	height: 13px;
	padding: 0;
	margin: 0;
	vertical-align: bottom;
	position: relative;
	top: -5px;
	overflow: hidden;
}
.field-report-container {
	display: flex;
}
.file-upload-lable {
	font-weight: normal;
	width: 31%;
	text-align: right;
	padding-top: 6px;
}
.download-btn {
	margin-left: 20px;
}
.red {
	color: #dc3545;
	font-weight: 700;
}
.c-dash {
	font-size: 16px;
	font-weight: 600;
	color: #7c7c7b;
}
.review-case-details-block .react-dropdown-select-input {
	width: calc(35ch + 4px) !important;
}
/*Datalist css*/
.file-list { width: 100%; }
.file-list, .surgeon-list {
	background: #fff;
    border-radius: 5px;
}
.files-action {
    display: flex;
    align-items: center;
}
.action-wrap {
    padding: 2px 3px;
    margin: 0 10px;
    cursor: pointer;
}
table {
	margin-bottom: 10px;
	font-size: 14px;
	text-align: center;
}
th,
td {
	margin: 0;
	padding: 0.5rem;
}
td {
	word-break: break-word;
}
.file-list th,
.file-list td {
	width: 9% !important;
}
.file-list .file-warning td:nth-child(4) {
	text-align: center;
	padding-left: 2.11%;
}
.file-details-table {
	width: 100% !important;
}
.file-details-table thead {
	background-color: #ffffff;
	text-align: center;
}
.file-details-table th,
.file-details-table td {
	width: 10% !important;
}
.file-details-tr-text:hover {
	background-color: white !important;
}
.file-details-col-heading:hover {
	background-color: white !important;
}
th {
	color: #575756;
}
@keyframes moving-gradient {
	0% {
		background-position: -250px 0;
	}
	100% {
		background-position: 250px 0;
	}
}
.loading-cell {
	height: 59px;
	vertical-align: middle;
	padding: 0.5rem;
}
.loading-cell span {
	display: block;
	height: 12px;
	background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
	background-size: 500px 100px;
	animation-name: moving-gradient;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}
.pagination {
	padding: 0.5rem;
	margin-left: 40px;
	font-size: 14px;
	color: #575756;
}
.datalist-style {
	margin-top: 20px !important;
	left: 22%;
	position: absolute;
	width: 76%;
	background: #fff;
	border-radius: 5px;
}
.file-list-heading {
	margin: auto;
	line-height: 2;
	font-size: 18px;
	margin-top: 15px;
	color: #414042;
	font-weight: 600;
}
.data-list-heading {
	margin: auto;
	padding-top: 10px 0px;
	line-height: 2;
	font-size: 18px;
	margin-bottom: 10px;
	color: #575756;
}
.tab-heading {
	margin: auto;
	padding-top: 20px 0px;
	line-height: 2;
	width: 100%;
	font-size: 24px;
	margin-bottom: 10px;
	color: #575756;
	text-transform: uppercase;
}
.pagination button {
	margin-right: 5px;
	background: #73BC51;
	border-radius: 5px;
	border: none;
	color: #fff;
	width: 39px;
}
.pagination select {
	width: 14%;
}
.page-display {
	padding-top: 10px;
}
.facility-note {
	font-size: 12px;
	color: #7c7c7b;
}
.file-list-error-msg {
	text-align: center;
	padding-bottom: 10px;
	color: #dc3545;
}
.data-list-error-msg {
	text-align: center;
	color: #dc3545;
}
.download-overlay {
	left: 22px;
	top: -20px;
	width: 99.4%;
}
.add-surgeon-overlay {
	left: -2px;
	width: 100.4%;
}
/* Case list style */
.case-actions {
	width: 80px;
}
.case-action-btn {
	margin-right: 5px;
}
#case-list td:nth-child(1) {
	width: 13% !important;
}
#case-list td:nth-child(2) {
	width: 5% !important;
	text-transform: capitalize;
}
#case-list td:nth-child(3) {
	width: 5% !important;
}
#case-list td:nth-child(4) {
	width: 10% !important;
}
#case-list td:nth-child(5) {
	width: 15% !important;
}
#case-list td:nth-child(6) {
	width: 12% !important;
}
#case-list td:nth-child(7) {
	width: 10% !important;
}
#case-list td:nth-child(8) {
	width: 6% !important;
}
#case-list td:nth-child(9) {
	width: 15% !important;
}
#case-list td:nth-child(10) {
	width: 5% !important;
}
.rar-view #case-list td:nth-child(1) {
	width: 9% !important;
	text-transform: capitalize;
}
.rar-view #case-list td:nth-child(2) {
	width: 3% !important;
}
.rar-view #case-list td:nth-child(3) {
	width: 7% !important;
}
.rar-view #case-list td:nth-child(4) {
	width: 12% !important;
}
.rar-view #case-list td:nth-child(5) {
	width: 7% !important;
}
.rar-view #case-list td:nth-child(6) {
	width: 7% !important;
}
.rar-view #case-list td:nth-child(7) {
	width: 6% !important;
}
.rar-view #case-list td:nth-child(8) {
	width: 9% !important;
}
.rar-view #case-list td:nth-child(9) {
	width: 5% !important;
}
.rar-view #case-list td:nth-child(10) {
	width: 5% !important;
}
.surgeon-view #case-list td:nth-child(1) {
	width: 18% !important;
}
.surgeon-view #case-list td:nth-child(2) {
	width: 9% !important;
}
.surgeon-view #case-list td:nth-child(3) {
	width: 5% !important;
}
.surgeon-view #case-list td:nth-child(4) {
	width: 8% !important;
}
.surgeon-view #case-list td:nth-child(5) {
	width: 10% !important;
}
.surgeon-view #case-list td:nth-child(6) {
	width: 6% !important;
}
.surgeon-view #case-list td:nth-child(7) {
	width: 8% !important;
}
.surgeon-view #case-list td:nth-child(8) {
	width: 10% !important;
}
.surgeon-view #case-list td:nth-child(9) {
	width: 5% !important;
}
.surgeon-view #case-list td:nth-child(10) {
	width: 5% !important;
}
.surgeon-view .case-actions,
.careteam-view .case-actions,
.rar-view .case-actions {
	margin: auto;
}
.caselist-tabs table,
table.review-case-list {
	width: 100%;
}
.case-list-top-block {
	display: flex;
	flex-flow: row;
}
.case-list-heading {
	width: 88%;
}
.case-list-top-btn {
	margin-top: -48px;
	float: right;
	margin-right: 2px;
}
.case-list-filter ul.filter-options {
	display: flex;
	padding: 10px;
}
#logsfilter ul.filter-options {
	margin-bottom: 0;
}
.case-list-filter ul.filter-options>li {
	text-align: left;
	display: flex;
	flex-flow: column;
	width: 31.25%;
	margin-left: 20px;
	font-weight: 500;
}
.filter-btns {
	margin-right: 10px;
	flex-flow: row !important;
	height: 38px;
	margin-top: 24px;
}
.filter-btns button {
	margin-right: 10px;
	height: 30px;
}
.filter-btn-width {
	width: 40%;
}
.data-list-tabs table {
	margin-left: 0px;
}
.data-list-tabs .pagination {
	margin-left: 0px;
}
.case-details-container {
	display: flex;
	margin-left: -20px;
	margin-top: 10px;
}
.case-details-heading {
	margin: auto;
	line-height: 1;
	font-size: 24px;
	margin-bottom: 10px;
	color: #575756;
	margin-left: 20px;
}
.case-details-back-btn {
	width: 20%;
}
.case-details-left {
	width: 43%;
}
.case-details-right {
	width: 57%;
}
.patient-details .card-body {
	height: 255px;
	text-transform: capitalize;
}
.rom-body .card-body {
	height: 295px;
}
.components-body .card-body {
	min-height: 578px;
}
.components-body.manual .card-body {
	min-height: 532px;
}
.case-details-left-blocks,
.case-details-center-blocks,
.case-details-right-blocks {
	border: 1px solid #e8edf1;
	border-radius: 5px;
	margin-left: 20px;
	margin-bottom: 15px;
}

.case-details-container label {
	font-weight: 400;
	margin-top: -6px !important;
}
.case-details-center {
	width: 100%;
}
.img-bg-color {
	border-radius: 4px;
	padding: 4px;
	background-color: #fff;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
	width: 81px;
}
.img-bg-color-preop {
	border-radius: 4px;
	background-color: #fff;
	margin-top: 5px;
	margin-bottom: 5px !important;
	width: 81px;
	height: 122px;
	margin-left: 30px;
}
.alignment-block {
	height: 255px;
}
.alignment-block .form-group {
	margin-top: 10px;
}
.alignment-block .img-alignment {
	margin-top: 15px;
}
.color-orange-left {
	color: #fda731;
	font-size: 12px;
}
.color-orange-right {
	color: #fda731;
	padding-right: 10px;
	font-size: 12px;
	display: table-row;
}
.color-purple-left {
	color: #c86ca6;
	padding-left: 5px;
	font-size: 12px;
	display: block;
}
.color-purple-right {
	color: #c86ca6;
	padding-left: 1px;
	font-size: 12px;
}
.align-image-text {
	display: flex;
	align-items: center;
	margin: auto;
	justify-content: center;
}
.align-text-middle {
	vertical-align: middle;
	padding-top: 20px;
	font-weight: 649;
}
.align-text-middle-single-point {
	vertical-align: middle;
	font-weight: 649;
}
.case-label-align-top {
	font-size: 12px;
	text-align: center;
	font-weight: 500 !important;
	color: #5a595b;
	padding-top: 20px;
}
.case-label-sub-heading {
	font-size: 12px;
	text-align: center;
	font-weight: 500 !important;
	color: #5a595b;
}
.case-text-align-below {
	font-size: 12px;
	color: #495057;
	padding-top: 10px;
}
.case-text-lower-row {
	margin-bottom: -3px;
	text-align: center;
}
.col-form-label {
	font-size: 12px;
	color: #5a595b;
}
.case-component-div {
	text-align: center;
	padding: 2px;
}
.components-body .femur-component {
	padding-left: 25px;
}
.components-body .case-component-border,
.measurements-wrap .case-component-border {
	border-right: 1px solid #5a595b;
}
.sub-heading-details {
	margin: auto;
	padding-top: 10px 0px;
	line-height: 2;
	width: 100%;
	font-size: 18px;
	margin-top: 30px;
	text-align: center;
	color: #333333;
	font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
}
.case-details-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #fff !important;
}
.case-details-tabs .nav-tabs .nav-link {
	color: #8A8A8A !important;
	width: 160px !important;
	margin-right: 2px;
	text-align: center;
	text-transform: uppercase;
	font-size: 13px;
	border-top: #fff;
	border-left: #fff;
	border-right: #fff;
}
.case-details-tabs .nav-tabs .nav-link.disabled {
	color: #ccc !important;
}
.data-list-tabs {
	margin-left: 158px;
	margin-top: 20px;
}
.data-list-tabs.caselist-tabs {
	margin-top: 60px;
}
.data-list-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #000000 !important;
	border-bottom: 5px solid #73BC51;
	border-top: #fff;
	border-left: #fff;
	border-right: #fff;
}
.data-list-tabs .nav-tabs .nav-link {
	background-color: #FFF !important;
	color: #8a8a8a !important;
	width: 160px !important;
	margin-right: 2px;
	text-align: center;
	text-transform: uppercase;
	font-size: 13px;
	border-top: #fff;
	border-left: #fff;
	border-right: #fff;
}
.gaps-container {
	clear: both;
}
.gaps-container canvas {
	width: 95%;
	height: 95%;
}
/*Analytics Scatter chart*/
.gaps-container-highchart {
	clear: both;
}
.flexion-range-input {
	width: 80px;
}
.flexion-range-margin {
	margin-left: 35px;
}
.case-details-gaps-title {
	font-size: 1rem;
	text-transform: uppercase;
	margin-left: 20px;
}
.proms-blocks tspan {
	text-transform: capitalize;
}
.modal-title {
	color: #7c7c7b;
	font-size: 16px;
	font-weight: 700;
}
/* case details with new block */
/* case analytics*/
.loading-img {
	text-align: center;
}
.loading-img img {
	width: 200px;
}
.gaps-tabs .nav-pills .nav-link.active {
	background-color: #73BC51 !important;
}
.gaps-tabs .nav-pills .nav-link {
	background-color: #565657;
	color: #fff !important;
}
.gaps-tabs .flex-row {
	flex-direction: row !important;
	margin-left: 40%;
}
.gaps-tabs .nav-item {
	margin-left: -13px !important;
}
.proms-blocks-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 0.5rem 1rem;
	height: 350px;
}
.proms-blocks-body.custom-fields {
	height: auto;
	padding-bottom: 30px;
}
.proms-block-title {
	font-size: 16px;
	font-weight: 700;
	color: #7c7c7b;
}
.proms-blocks-sub-heading {
	text-align: center;
	color: #7c7c7b;
}
.proms-form-label {
	width: 22%;
	margin-top: 5px;
	margin-right: 10px;
	text-align: right;
}
.proms-form-input {
	width: 56%;
}
.proms-form-btn {
	width: 100%;
	text-align: center;
}
.proms-btn-color {
	background-color: #73BC51 !important;
}
.proms-btn-cancel {
	margin-left: 10px;
	width: 91px;
}
.proms-blocks-body table {
	margin-left: 0px !important;
	margin-top: 10px;
}
.proms-top-error-msg {
	color: #dc3545;
	margin: auto;
	margin-top: 20px;
	width: 100%;
	text-align: center;
}
.proms-success-msg {
	color: green;
	margin: auto;
	width: 100%;
	margin-top: 5px;
	text-align: center;
}
.proms-error-msg {
	color: #dc3545;
	margin: auto;
	margin-bottom: -25px;
	font-size: 14px;
	padding-top: 5px;
}
.proms-help-msg {
	color: #8a8a8a;
	margin: auto;
	margin-bottom: -25px;
	font-size: 14px;
}
.proms-table .table-contents {
	height: 300px !important;
	overflow: auto;
}
.proms-table td:nth-child(4) {
	text-transform: capitalize;
}
.proms-blocks .loading-overlay {
	margin-left: -17px !important;
	margin-top: -69px !important;
}
/* css for dasboard */
/* .dashboard-container {
margin-top: -34px !important;
}  */
.dashboard-blocks {
	border: 1px solid #EBEBEB;
	border-radius: 6px;
	margin-bottom: 15px;
	background-color: #fff;
}
.dashboard-blocks-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 1rem 1rem;
	height: 350px;
}
.dashboard-block-title {
	font-size: 16px;
	font-weight: 700;
}
.dashboard-blocks-sub-heading {
	text-align: center;
	color: #7c7c7b;
}
.rar-dashboard-blocks-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 0.5rem 1rem;
	height: 200px;
}
.rar-dashboard-block-height {
	height: 160px !important;
}
.careteam-dashboard-block-height {
	height: 187px !important;
}
.rar-dashboard-block-title {
	text-align: center;
	color: #5a595b;
	font-size: 24px;
}
.rar-dashboard-block-sub-title {
	text-align: center;
	color: #8a8a8a;
	font-size: 16px;
}
.rar-btn-color {
	background: #73BC51 !important;
	font-size: 18px !important;
	border: 1px solid rgba(138, 138, 138, 0.5) !important;
	box-sizing: border-box;
	border-radius: 5px;
}
.rar-dashboard-btn {
	margin-top: 20px;
	text-align: center;
}
.height {
	height: 370px;
}
.text-aign-left {
	text-align: left;
}
.text-aign-right {
	text-align: right;
}
.text-aign-center {
	text-align: center;
}
.rar-dashboard-surgeon-list-body {
	height: 690px;
}
.careteam-dashboard-surgeon-list-body {
	height: 390px;
}
.license-body {
	height: 574px;
}
.find-surgeon {
	border-radius: 6px;
	border: 1px solid #8a8a8a;
	width: 200px;
	padding: 4px;
	margin: auto;
	text-align: center;
	color: #8a8a8a;
	font-size: 18px;
	cursor: pointer;
}
.rar-surgeon-list-block {
	height: 580px;
	margin-top: 15px;
	margin-bottom: 30px;
}
.rar-surgeon-list-block,
.proms-table table {
	width: 100%;
}

.add-surgeon-dialog {
	max-width: 70% !important;
}
.surgeon-search-container {
	display: flex;
	flex-direction: row;
	margin-left: 17%;
}
.add-surgeon-dialog .modal-content {
	height: 550px !important;
}
.surgeon-search-results {
	margin-top: 10px;
}
.serach-icon {
	margin-left: -20px;
	margin-top: 3px;
	cursor: pointer;
}
.delete-icon {
	margin-left: 10px;
}
.cursor-pointer {
	cursor: pointer;
}
.surgeon-search-results table {
	margin-left: 0px !important;
	width: 100%;
	position: relative;
}
.surgeon-search-results th:nth-child(1) {
	width: 24% !important;
}
.surgeon-search-results th:nth-child(2) {
	width: 18% !important;
}
.surgeon-search-results th:nth-child(3) {
	width: 16% !important;
}
.surgeon-search-results th:nth-child(4) {
	width: 30% !important;
}
.surgeon-search-results td:nth-child(1) {
	width: 16% !important;
}
.surgeon-search-results td:nth-child(2) {
	width: 11% !important;
}
.surgeon-search-results td:nth-child(3) {
	width: 11% !important;
}
.surgeon-search-results td:nth-child(4) {
	width: 19% !important;
}
.surgeon-search-results td:nth-child(5) {
	width: 7% !important;
}
.surgeon-search-results tr.result-count td {
	width: 1% !important;
}
.rar-surgeon-list {
	border: 1px solid #dee2e6;
	border-radius: .25rem;
	padding: 12px;
	text-align: center;
	margin-left: 1px;
	color: #7c7c7b;
	margin-bottom: 10px;
}
/*Global Vs My Case Details*/
.globalVsMycase-barChart {
	opacity: 0.8;
}
.globalVsMycase-barChart .no-data-found {
	margin-top: 10% !important;
	margin-bottom: 10% !important;
}
.globalVsMycaseDetails .dashboard-blocks-body {
	display: flex;
	flex-direction: column;
    justify-content: space-between;
}
.last-updated-text {
	font-size: 12px;
	font-weight: 500;
}
/*Outcome Score Block*/
.q1-bar-graph {
	text-transform: capitalize;
	width: 100%;
	opacity: 0.8;
}
.q1-bar-graph .no-data-found {
	text-align: center;
	color: #dc3545;
	margin: 12% 0;
}
/*Outcome Score Block and Case Aligment*/
.quadrant-tabs .nav-pills .nav-link.active {
	background-color: #73BC51 !important;
	color: #fff !important;
}
.quadrant-tabs .nav-pills .nav-link {
	color: #73BC51 !important;
	border: 1px solid #73BC51;
	padding: 5px 20px;
}
.quadrant-tabs .nav-item {
	margin-left: 2px !important;
    width: 33%;
	text-align: center;
	cursor: pointer;
}
.quadrant-alignment-row-heading {
	text-align: center;
	margin: 5px 1px 0;
	color: #7c7c7b;
	padding: 1px 18px;
}
.quadrant-alignment-row-values {
	border: 1px solid #dee2e6;
	border-radius: .25rem;
	padding: 15px;
	text-align: center;
	margin: 5px 1px 10px;
	color: #7c7c7b;
}
*:focus {
	outline: none;
}
.dashboard-select {
	right: 1%;
	top: 3%;
	display: flex;
	align-items: center;
	flex-direction: row;
	position: absolute;
}
.surgeon-details-card {
	border-radius: 6px;
	background-color: #fff;
}
.surgeon-id-label {
	font-weight: 600;
}
.profile-surgeoncard img {
	width: 60px;
	height: 60px;
	background-color: #C4C4C4;
	mix-blend-mode: normal;
	border-radius: 50%;
}
.customer-detail {
	padding: 0 10px;
	line-height: 12px;
}
.dashboard-select-surgeon-lable {
	padding: 5px;
	width: 25%;
	font-weight: 600;
}
.dashboard-select-lable {
	padding: 5px;
	width: 100%;
	font-weight: 600;
	text-align: center;
}
.dashboard-surgeon-id-label {
	padding: 5px;
	width: 80%;
	font-weight: 600;
}
.dashboard-heading {
	text-align: center;
	font-size: 24px;
	margin-top: 20px;
}
.no-data-found {
	margin-top: 20%;
	text-align: center;
	color: #dc3545;
	margin-bottom: 20%;
}
.loading-img-center {
	margin-top: 20%;
}
.alignment-no-data {
	text-align: center;
	color: #dc3545;
}
.timeout-display {
	margin: auto;
}
.error-block,
.mandatory {
	color: #dc3545;
}
/* Text Editor */
.ck-content {
	min-height: 100px;
	max-height: 200px;
	overflow: auto;
}
.word-count {
	color: #555;
}
/* Range Input */
.range-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 15px 10px 0;
}
.range-track-wrap {
	height: 36px;
	display: flex;
	width: 100%;
}
.range-track {
	height: 5px;
	width: 100%;
	border-radius: 4px;
	align-self: center;
}
.range-thumb {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color: #FFF;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #AAA;
}
.range-thumb-bubble {
	position: absolute;
	top: -33px;
	color: #5a595b;
	font-size: 13px;
	padding: 4px 10px;
	border-radius: 4px;
	background: #fff;
	width: max-content;
	border: 1px solid #ccc;
	font-weight: normal;
}
.range-thumb-bubble-tick {
	height: 10px;
	width: 5px;
}
.range-extreme-label-min {
	position: absolute;
	top: 26px;
	left: -10px;
	font-size: 14px;
	color: #5a595b;
	font-weight: normal;
}
.range-extreme-label-max {
	position: absolute;
	top: 26px;
	right: -10px;
	font-size: 14px;
	color: #5a595b;
	font-weight: normal;
}
/* Review Planning Tool start*/
.planning-review-tool label {
	font-weight: 500;
}
.planning-review-body {
	height: auto;
}
.planning-review-body .case-count,
.planning-review-body label {
	color: #5a595b;
}
.slider-text {
	background: transparent !important;
	border: none !important;
	padding-right: 0 !important;
	text-align: right;
}
.planning-review-tool .case-component-div {
	border: 1px solid #ccc;
	border-radius: 4px;
	width: 32.5%;
	padding: 5px 15px 30px;
	box-shadow: 0px 2px 6px 0px #ccc;
}
.planning-review-tool .img-bg-color {
	min-width: 130px;
	min-height: 200px;
}
.planning-review-tool .case-component-div label {
	display: block;
	text-align: left;
	font-size: 14px;
	margin: 0 !important;
}
.planning-review-tool .case-component-div .case-text-align-below,
.planning-review-tool .case-component-div .align-image-text,
.planning-review-tool .case-component-div .align-text-middle .color-orange-left,
.planning-review-tool .case-component-div .align-text-middle-single-point .color-orange-left {
	margin-left: inherit !important;
}
.planning-review-tool .case-component-div .align-text-middle,
.planning-review-tool .case-component-div .align-text-middle-single-point {
	margin: 0 0.5rem;
}
.planning-review-tool .case-component-div .align-image-text {
	justify-content: center;
}
.planning-review-tool .case-component-div span.value {
	font-size: 20px;
}
.planning-review-tool .case-component-div span.unit {
	font-size: 12px;
}
/* Custom checkboxes */
.custom-control-label {
	vertical-align: middle;
}
.custom-control-label::before,
.custom-control-label::after {
	width: 1.5rem;
	height: 1.5rem;
	left: -2rem;
	top: .1rem;
}
.custom-control {
	min-height: 2rem;
	padding-left: 2rem;
	vertical-align: middle;
}
.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	border-color: #73bc51;
	background-color: #73bc51;
	top: .1rem;
}
/* Review Planning Tool end */
/**** New styles for login */
.login-logo {
	margin-bottom: 5%;
}
.login_content input {
	margin-bottom: 16px;
	height: 40px;
}
.forgot-password-text {
	margin-top: 8px;
	margin-right: 17px;
	color: #8a8a8a;
	cursor: pointer;
	font-size: 14px;
}
.login-btn-block {
	margin-left: 25%;
}
.login-page {
	background-color: #fff;
	height: 100vh;
}
.bg-white {
	background-color: #fff;
}
/* side nav new styles start*/
.application-logo {
	padding: 20px 5px;
	border-bottom-style: solid;
	border-bottom-color: #E0E0E0;
	margin: auto;
}
.application-logo img {
	width: 75px;
	height: 67px;
	left: 0;
	top: 26px;
}
.logout-profile {
	margin-top: 10px;
	margin-bottom: 20px;
}
.logout {
	margin-top: 36px;
	padding: inherit;
}
.logout a {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #8A8A8A;
	text-decoration: none;
	padding-top: 2%;
	margin-left: 21px;
}
.logout img {
	margin-right: -10px;
}
.profile img {
	width: 70px;
	height: 70px;
	background-color: #C4C4C4;
	mix-blend-mode: normal;
	border-radius: 50%;
}
.sidenav-profile {
	border-bottom-style: solid;
	color: #E0E0E0;
	width: 239px;
	background: #fff;
	border-right: 1px solid #E0E0E0;
}
.sn-bottom-logo {
	background: #fff;
	width: 239px;
	text-align: center;
	padding-top: 131px;
	padding-bottom: 20px;
	border-right: 1px solid #E0E0E0;
	position: relative;
	z-index: 1;
}
.sn-bottom-logo img {
	width: 150px;
	height: 20px;
}
.application-text {
	text-align: left;
	margin-top: 12px;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	color: #8A8A8A;
}
.surgeon-info {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #8A8A8A;
	margin-left: 10%;
	margin-top: 5%;
}
.surgeon-sub-info {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
}
/* side nav new styles end*/
/*New styles for dashboard tabs start*/
.dashboard-container .nav-tabs {
	border-bottom: 1px solid #dee2e6;
	padding: 10px;
	background: #fff;
	border-radius: 6px;
	/* margin-top: 31px !important; */
}
.data-list-tabs .nav-tabs .nav-link.active {
	color: #000000 !important;
	border-bottom: 5px solid #73BC51;
	border-top: #fff;
	border-left: #fff;
	border-right: #fff;
}
.padding-inherit {
	padding: inherit;
}
.right-block {
	padding-left: 15px;
}
/*New styles for dashboard tabs end*/
/*New styles for case-list tabs start*/
.data-list-tabs .nav-tabs {
	background: #fff;
	border-radius: 6px;
	padding-top: 2%;
	margin-bottom: 2%;
}
.caselist-tabs .tab-content {
	margin-top: -14px;
}
.caselist-tabs .case-list-tab,
.caselist-tabs .chart-blocks {
	border-radius: 6px;
	background: #fff;
	padding: 15px;
}
.caselist-tabs .chart-blocks {
	margin-left: 0;
	margin-right: 0;
}
.caselist-tabs .chart-blocks:last-child {
	margin-bottom: 1.5rem !important;
}
.dashboard-container .tab-content {
	background: none !important;
	padding: 0px !important;
}
.dashboard-left-block {
	padding-left: 13px;
	padding-right: 10px;
}
/*New styles for case-list tabs end*/
/*New styles for HIP tab start*/
.hip-dashboard-tilt-range {
	min-width: 70%;
}
.hip-tilt-value {
	font-weight: 600;
	color: #73bc51;
}
.hip-dashboard-tilt-img img {
	width: 50%;
}
/*New styles for HIP tab tabs end*/
/* New styles for datatable start*/
.table-contents {
	margin-top: 1%;
	background: #fff;
}
.table-bordered td,
.table-bordered th {
	border: 0px solid #E0E0E0;
	border-bottom: 1px solid #E0E0E0;
}
.table-bordered {
	border: 0px solid #E0E0E0;
}
tr:nth-child(even) {
	background-color: #EDEDED;
}
tbody tr:hover {
	background-color: #e3fcd8 !important;
}
thead {
	background-color: #EDEDED;
	text-align: center;
}
.outcome-score-title {
	padding: inherit;
	padding-left: 15px;
}
/* New styles for datatable end*/
/* New styles for trends tab start*/
.chart-blocks {
	border: 1px solid #fff;
	border-radius: 6px;
	margin-left: 1%;
	margin-right: 1%;
	margin-bottom: 1%;
	margin-top: 1%;
}
.chart-blocks h3 {
	color: #333333;
	font-size: 18px;
	fill: #333333;
	font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
}
/* New styles for trends tab end*/
/* New styles for case summary tab start*/
.case-details-top-block {
	display: flex;
	flex-flow: row;
	background: #fff;
	border-radius: .55rem;
	padding-top: 1%;
	padding-bottom: 1%;
	padding-right: 1%;
	margin-bottom: 1%;
}
.case-details-tabs .nav-tabs {
	background: #fff;
	border-radius: .55rem;
}
.case-details-comp-blk1 {
	padding-left: 33px;
}
.case-details-comp-blk2 {
	padding-left: 62px;
}
/* New styles for case summary tab start*/
/* New styles for case filter tab start*/
.case-list-filter {
	border: 1px solid rgba(0, 0, 0, 0.125);
	margin-bottom: 20px;
	padding-top: 1%;
	border-radius: .55rem;
	padding-bottom: 1%;
	background-color: #fff;
	margin-top: 13px;
}
.case-list-filter select.form-select {
    color: #5d666e;
    font-size: 0.93rem !important;
    line-height: 1.7;
}
.case-list-filter input.form-control {
    color: #5d666e;
    font-size: 0.93rem !important;
    line-height: 1.7;}
/* New styles for case filter tab end*/
/* disabled buttons */
button:disabled,
input:disabled {
	opacity: 0.8;
}
/* New styles for caselist-overview tab start*/
.case-details-container .card-body {
	border-radius: 6px;
	background-color: #fff;
}
.case-details-container .card-title {
	font-size: 1rem;
	text-transform: uppercase;
	padding-bottom: 2%;
}
.card-body .img-bg-color-preop {
	margin-top: -5px !important;
	width: 70px !important;
}
.card-body .flexion-range {
	margin-top: 10%;
	width: 130px;
	margin-left: 10px;
}
.case-details-gaps {
	border: 1px solid #fff;
	background-color: #fff;
	border-radius: 6px;
	padding-top: 2%;
	margin-bottom: 25px;
}
/* New styles for caselist-overview tab start*/
/* New styles for caselist-casetime tab start*/
.timing-data-display {
	clear: both;
	border-radius: 6px;
	border: 1px solid #fff;
	background: #fff;
	margin-top: 1%;
	margin-bottom: 2%;
	padding-bottom: 1%;
}
.case-timing-data-display {
	clear: both;
	border-radius: 6px;
	border: 1px solid #fff;
	background: #fff;
	margin-bottom: 2%;
	padding-bottom: 1%;
}
/* New styles for caselist-casetime tab end*/
/* New styles for caselist-promscores tab start*/
.proms-blocks {
	margin-bottom: 15px;
	border: 1px solid #fff;
	background: #fff;
	border-radius: 6px;
}
/* New styles for caselist-promscores tab end*/
.file-upload {
	border: 1px solid #fff;
	background: #FFF;
	margin-top: 20px;
	padding-bottom: 2%;
	border-radius: 6px;
	margin-left: 14%;
	min-height: 100%;
}
.planning-review-tool .case-component-div .case-label-align-top {
	margin-top: -10px;
}
/* New styles for case details page */
.datalist-style.case-details-wrap {
	margin-top: 1px;
	margin-left: 158px;
	max-width: 100%;
	background: transparent;
	border-radius: 5px;
	position: unset;
	width: unset !important;
}
.prom-loader-wrap {
	height: 85%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.case-details-wrap .nav-tabs {
	background: #fff;
	border-radius: .55rem;
	padding-top: 2%;
	margin-bottom: 1%;
}
.case-component-div .align-image-text .align-text-middle,
.case-component-div .align-image-text .align-text-middle-single-point {
	min-width: 35px;
}
/* New styles for case details page end */
/* New styles for planning tool page */
.planning-tool-wrap .tab-content {
	background: none !important;
	padding: 0 !important;
	border-radius: 6px;
}
/* New styles for planning tool page end */
/* style start for toggle switch */
.switch {
	position: relative;
	display: inline-block;
	width: 80px;
	height: 34px;
	margin-bottom: 0;
	vertical-align: middle;
}
.switch input {
	display: none;
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #8A8A8A;
	-webkit-transition: .4s;
	transition: .4s;
}
.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}
input:checked+.slider {
	background-color: #73BC51;
}
input:focus+.slider {
	box-shadow: 0 0 1px #2196F3;
}
input:checked+.slider:before {
	-webkit-transform: translateX(46px);
	-ms-transform: translateX(46px);
	transform: translateX(46px);
}
.on {
	display: none;
	color: white;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 35%;
	font-size: 10px;
	font-family: Verdana, sans-serif;
}
.off {
	color: white;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 67%;
	font-size: 10px;
	font-family: Verdana, sans-serif;
}
input:checked+.slider .on {
	display: block;
}
input:checked+.slider .off {
	display: none;
}
.slider.round {
	border-radius: 34px;
}
.slider.round:before {
	border-radius: 50%;
}
/* style end for toggle switch */
.rar-new-request {
	background-color: #ff9e1817 !important;
}
.rar-request-list {
	background: #fff;
	border-radius: 5px;
}
.rar-request-list-table {
	height: 400px;
	margin-bottom: 30px;
	overflow: auto;
}
.rar-request-list-table table {
	width: 100%;
}
.setting-header {
	padding: 2px;
	background: #fff;
	margin-left: 14%;
	margin-top: 1px;
	border-radius: 5px;
}
.graphs-label-tooltip {
	position: relative;
	display: inline-block;
}
.graphs-label-tooltip.label-link {
	cursor: pointer;
}
.graphs-label-tooltip .tooltiptext {
	visibility: hidden;
	background-color: #555;
	color: #fff;
	border-radius: 6px;
	padding: 10px;
	position: absolute;
	z-index: 1;
	top: 125%;
	right: -128px;
	text-align: left;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}
.graphs-label-tooltip .tooltiptext::before {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 54%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #555 transparent;
}
.graphs-label-tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
/* Case List Filter style adjustments start */
#casefilter ul.filter-options {
	margin-right: 20px;
}
#casefilter label {
	font-weight: normal;
	color: inherit;
}
#casefilter .range-wrapper {
	margin: 8px 10px 0;
}
#casefilter .range-thumb-bubble,
.planning-review-tool .range-thumb-bubble {
	top: 21px;
}
#casefilter .range-extreme-label-min,
#casefilter .range-extreme-label-max,
.planning-review-tool .range-extreme-label-min,
.planning-review-tool .range-extreme-label-max {
	top: -12px;
}
/* Case List Filter style adjustments end */
/* css for surgeon search list */
.surgeon-search-results .rt-thead {
	position: sticky;
	top: 0;
	z-index: 2;
}
.surgeon-search-results .rt-tbody {
	overflow: auto !important;
	height: 270px;
	position: absolute;
	width: 100%;
}
/* css for surgeon list */
.rar-surgeon-list-block table {
	width: 100% !important;
}
.rar-surgeon-list-block .rt-thead {
	position: sticky;
	top: 0;
	z-index: 2;
}
.rar-surgeon-list-block .rt-tbody {
	overflow: auto !important;
	height: 540px;
	position: absolute;
	width: 60.85%;
}
.careteam-mapping-list .rt-tbody { height: 250px; }
.rar-surgeon-list-block th:nth-child(1) {
	width: 4% !important;
}
.rar-surgeon-list-block th:nth-child(2) {
	width: 4% !important;
}
.rar-surgeon-list-block th:nth-child(3) {
	width: 4% !important;
}
.rar-surgeon-list-block td:nth-child(1) {
	width: 4% !important;
}
.rar-surgeon-list-block td:nth-child(2) {
	width: 4% !important;
}
.rar-surgeon-list-block td:nth-child(3) {
	width: 4% !important;
}
.rar-surgeon-list-block .tr-refresh td {
	width: 50% !important;
}
/* css for rar association request */
.rar-request-list-table .rt-thead {
	position: sticky;
	top: 0;
	z-index: 2;
}
.rar-request-list-table .rt-tbody {
	overflow: auto !important;
	max-height: 320px;
}
.rar-request-list-table th:nth-child(1) {
	width: 14.5% !important;
	text-align: center;
}
.-table th:nth-child(2) {
	width: 4.5% !important;
	text-align: center;
}
.rar-request-list-table td:nth-child(1),
.rar-request-list-table td:nth-child(2) {
	width: 16% !important;
	text-align: center;
}
.rar-request-list-table td:nth-child(3) {
	width: 4.5% !important;
}
.rar-request-list-table .tr-refresh td:nth-child(1) {
	width: 100% !important;
}
input[type='checkbox'].checkbox {
	width: 12px !important;
	height: 12px !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	outline: 1px solid gray;
	box-shadow: none;
	font-size: 0.8em;
	text-align: center;
	line-height: 1em;
	background: transparent;
}
input[type='checkbox']:checked,
.form-check-input:checked {
	background-color: #73BC51 !important;
	border-color: #73BC51 !important;
}
input[type='checkbox'].checkbox:checked:after {
	content: '✔';
	color: white;
	font-size: 11px;
	vertical-align: top;
}
.multiSelectContainer::after {
	content: '';
	height: 8px;
	width: 8px;
	border-right: 2px solid #495057;
	border-bottom: 2px solid #495057;
	position: absolute;
	right: 15px;
	z-index: 1;
	transform: rotate(45deg);
	top: 37%;
}
/* Font color changes */
body,
.card-title,
.file-list-heading {
	color: #5a595b !important;
}
/* Custom scrollbar start */
/* For Firefox */
.faq-block {
	scrollbar-color: #c0c0c0 #f1f1f1;
	scrollbar-width: thin;
}
/* width */
.faq-block::-webkit-scrollbar {
	width: 8px;
}
/* Track */
.faq-block::-webkit-scrollbar-track {
	background: #f1f1f1;
}
/* Handle */
.faq-block::-webkit-scrollbar-thumb {
	background: #c0c0c0;
	border-radius: 5px;
}
/* Handle on hover */
.faq-block::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}
/* Custom scrollbar end */
/* export button */
.export-btn {
	position: absolute;
	text-align: right;
	right: 30px;
	top: 15px;
}
.logs-export-btn {
	margin-left: 36%;
	position: absolute;
	width: 40%;
	text-align: right;
	margin-top: 2.1%;
}

.has-error {
	border-color: #dc3545 !important;
}

/* account tab  starts */
.reset-password {
	width: 100%;
	background: #fff;
	border-radius: 5px;
	padding: 16px;
}
.reset-password-heading {
	font-size: 18px;
}
.reset-password .reset-password-input-wrap {
	margin-bottom: 20px;
}
.reset-password .reset-password-input-wrap.new-password .new-password-errors {
	margin-top: 20px;
}
.reset-pwd-error-msg {
	color: #dc3545;
	width: 66%;
}
.reset-pwd-success-msg {
	color: green;
	width: 66%;
}
.reset-password .progress {
	margin-top: -14px !important;
	margin-bottom: 8px !important;
}
.pwd-error {
	color: #dc3545;
}
.pwd-success {
	color: green;
}
.reset-form.w-40 {
	min-width: 47% !important;
}
.reset-password ul {
	list-style-type: none;
	margin: 0 !important;
	padding-left: 0 !important;
	font-size: 0.8rem;
}
.reset-password li.pwd-success::before {
	content: '✓';
	padding-right: 10px;
	font-weight: 800;
}
.reset-password li.pwd-error::before {
	content: 'X';
	padding-right: 10px;
	font-weight: 700;
}
/*  account tab ends */
/* forgot password css starts */
.forgot-pwd-success-msg {
	color: green;
	margin: auto;
	text-align: center;
}
.forgot-pwd-error-msg {
	color: #dc3545;
	margin: auto;
	text-align: center;
}
.auth-code-success {
	color: green;
	text-align: center;
	margin-bottom: 15px;
}
.resend-btn {
	width: 170px;
}
.forgot-pwd-btns {
	float: right;
}
/* forgot password ends */
/* logs start */
.app-logs {
	height: 97vh;
	background: #fff;
	padding: 13px;
	border-radius: 11px;
}
.app-logs .file-list-heading {
	margin: auto 0 !important;
}
.app-logs .table-contents { position: relative; height: 85%; }
.app-logs table.audit-logs {
	margin-left: 0px !important;
	position: relative;
	height: 82vh;
	width: 100%;
}
.app-logs .rt-tbody {
	overflow: auto !important;
	position: absolute;
	height: 85%;
	width: 100%;
}
.app-logs .rt-tbody.tr-refresh {
	position: relative;
}
.app-logs .pagination {
	overflow: hidden !important;
	position: absolute;
	bottom: 1%;
	margin: 0;
	width: 82%;
}
.app-logs th:nth-child(1),
.app-logs th:nth-child(2),
.app-logs th:nth-child(3),
.app-logs th:nth-child(4),
.app-logs th:nth-child(5),
.app-logs th:nth-child(6),
.app-logs th:nth-child(7) {
	width: 10% !important;
}
.app-logs th:nth-child(8) {
	width: 15.75% !important;
}

.app-logs th,
.app-logs td:nth-child(1),
.app-logs td:nth-child(2),
.app-logs td:nth-child(3),
.app-logs td:nth-child(4),
.app-logs td:nth-child(5),
.app-logs td:nth-child(6),
.app-logs td:nth-child(7) {
	width: 10% !important;
}
.app-logs th,
.app-logs td:nth-child(8) {
	width: 15% !important;
}

.logs-list-top-btn {
	margin-top: -49px;
	float: right;
	margin-right: 2px;
	margin-bottom: 5px;
}
.logs-filter {
	left: 22%;
	position: absolute;
	width: 76%;
	background: #fff;
	border-radius: 5px;
}
/*logs end */
/* Table row for result count */
tr.result-count,
tr.result-count:hover,
tr.result-count td {
	background: #fff !important;
	text-transform: none !important;
}
/* File upload page design udpates start */
.upload-selected {
	padding: 10px 30px !important;
	font-size: 18px !important;
}
/* File upload page design udpates end */

/* Customer details start */
.customer-fields .form-control {
	background: #fff;
	border: 1px solid #e4e4e4;
	border-radius: 3px;
	font-size: 12px;
}
.customer-fields .form-select {
	border: 1px solid #e4e4e4;
	border-radius: 3px;
	font-size: 12px;
}

.customer-fields input:disabled,
.customer-fields select:disabled {
	background-color: #f7f7f7 !important;
}

.customer-fields label {
	margin-top: 0 !important;
}

.customer-error-msg p {
	color: #dc3545;
	margin: 0 auto -10px;
	font-size: 12px;
}
.form-control.disabled,
.form-select.disabled {
	background: #f7f7f7 !important;
}
/* Customer details end */
/* file details start */
.file-details-heading {
	padding: 10px;
	float: left;
	font-size: 16px;
}
.file-details-tr-text {
	background-color: #fff !important;
}
.file-details-tr-heading {
	background: #fff;
	font-weight: 600;
	color: #575756;
}
.file-details-nodata {
	padding: 20px;
	font-weight: 600;
}
.file-details-tr-background {
	background-color: #EDEDED;
	border-bottom: 2px solid;
}
/* file details ends */
/*help page start */
.help-info {
	width: 100%;
	background: #fff;
	border-radius: 5px;
	padding: 16px;
}
/* help page ends */
.help-page .nav-tabs {
	margin-bottom: 1% !important;
}
/* FAQ Page start */
.faq-block {
	max-height: 81vh;
	overflow: auto;
}
.faq-block .card {
	border: none;
	border-radius: 8px;
}
.faq-block .card-header {
	cursor: pointer;
	font-weight: 700;
	text-transform: uppercase;
	padding: 20px;
	background: #fff;
}
/* FAQ Page end */
/* New customer page start */
.add-customer-btn {
	margin-top: 15px;
	float: right;
	background-color: #73BC51 !important;
}
.add-customer-actions-btn,
.add-license-actions-btn {
	width: 100%;
	text-align: right;
}
.add-customer-btn-cancel,
.add-license-btn-cancel {
	margin-left: 10px;
}
.customer-save-success-msg {
	color: green;
	margin: auto;
	width: 100%;
	margin-top: -10px;
	text-align: center;
}
.license-save-success-msg {
	color: green;
	margin: auto;
	width: 100%;
	margin-top: -30px;
	text-align: center;
}
.customer-save-error-msg,
.license-save-error-msg {
	color: #dc3545;
	margin: auto;
	text-align: center;
}
.expor-customer-to-csv-btn {
	margin-left: 10px;
	margin-top: 15px;
}
.export-customer-error-block {
	color: #dc3545;
	text-align: center;
}
/* New customer page ends */
/* Device Count started */
h2.device-count {
    font-size: 5rem;
	font-weight: 600 !important;
}
/* Device Count Ends */
/* User list page and modals in it */
.user-list-container {
	max-height: 710px;
	overflow: auto;
}
.user-list-container .user-list .loading-cell {
	height: 45px
}
.user-details-dialog {
	max-width: 55% !important;
}
.user-details-dialog .modal-body {
	min-height: 65px;
}
.user-details-dialog .loading-overlay-upload {
	left: 0 !important;
}
.user-details-dialog .loading-overlay-upload span {
	padding-bottom: 20px;
}
.user-details-dialog .case-details-container {
	margin: 10px 0 !important;
}
.user-details-dialog .col-form-label {
    font-weight: 500;
}
.user-details-dialog .add-user-container .error-block,
.add-device-dialog .add-device-container .error-block {
	font-size: 13px !important;
}
.user-details-dialog .add-user-container .error-block label,
.add-device-dialog .add-device-container .error-block label {
	font-size: 13px;
	color: #dc3545;
	font-weight: 700;
	margin: 0;
}
.reset-password-dialog {
	max-width: 30% !important;
}
/* User list page end */
/* N/A Helptext start */
.na-help-text {
	font-size: 12px;
	font-weight: 500;
}
.case-details-na {
	position: absolute;
	right: 2%;
	top: 125px;
}
/* N/A Helptext end */
.btn:focus,
.btn:active,
.btn:hover {
	outline: 0 !important;
	box-shadow: none;
}
/* Custom Multi Select Start */
.btn-dropdown-text,
.btn-dropdown-text:active,
.btn-dropdown-text:hover,
.btn-dropdown-text:focus {
	padding-left: 10px !important;
	padding-right: 10px !important;
	text-align: left;
	min-width: 150px;
	box-shadow: none !important;
}
.btn-dropdown-text::after {
	position: absolute;
	right: 5px;
	top: 45%;
}
.btn-dropdown-arrow,
.btn-dropdown-arrow:hover,
.btn-dropdown-arrow:active,
.btn-dropdown-arrow:focus {
	padding: 0 5px !important;
}
.case-pull-dropdown-wrap .dropdown-menu {
	min-width: 150px;
}
.case-pull-dropdown-wrap .dropdown-menu.show {
	transform: translate(0px, 41px) !important;
	padding: 0;
}
.case-pull-option.dropdown-item {
	padding: 0.25rem 0.5rem !important;
	font-size: 1rem;
}
.case-pull-option.dropdown-item.selected {
	background: #aada94;
	color: #000;
}
.customer-select-dropdown,
.customer-select-dropdown:hover,
.customer-select-dropdown:focus,
.customer-select-dropdown:active,
.casepull-select-dropdown,
.casepull-select-dropdown:hover,
.casepull-select-dropdown:focus,
.casepull-select-dropdown:active {
	border-radius: 5px !important;
	padding: 8px 5px 8px 8px !important;
}
.customer-select-dropdown,
.customer-select-dropdown .react-dropdown-select-dropdown {
	min-width: 250px;
}
.casepull-select-dropdown {
	min-width: 150px;
}
.casepull-select-dropdown .react-dropdown-select-input {
	display: none;
}
.casepull-select-dropdown .react-dropdown-select-dropdown {
	top: 40px;
}
.select-custom-button {
	background: none;
	border: 1px solid #73bc51;
	color: #73bc51;
	border-radius: 3px;
	margin: 5px 0;
	padding: 3px 5px;
	font-size: 10px;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
}
.select-custom-button.clear {
	color: #73bc51;
	border: 1px solid #73bc51;
}
.select-custom-button:hover {
	border: 1px solid #73bc51;
	color: #73bc51;
}
.custom-select-dropdown {
	overflow: auto;
	min-height: 10px;
	max-height: 200px;
}
.custom-select-dropdown-item {
	display: flex;
	padding: 0 10px;
	align-items: baseline;
	cursor: pointer;
}
.custom-select-dropdown-item:hover,
.case-pull-option.dropdown-item:hover {
	background: #73bc51;
	color: #fff;
}
.custom-select-search {
	height: 30px;
	font-size: 14px;
	padding: 5px;
}
.custom-select-title {
	text-transform: uppercase;
	font-weight: 600;
	padding: 0 5px;
}
.react-dropdown-select-item {
	text-align: left !important;
}
/* Custom Multi Select End */
.hip-cup-img {
	width: 55%;
}
.text-orange {
	color: #fda731;
}
.text-purple {
	color: #c86ca6;
}
.text-green {
	color: #73bc51;
}
.text-light-grey {
	color: #bfbfbf;
}
.fs-8-rem {
	font-size: 0.8rem;
}
.fs-9-rem {
	font-size: 0.9rem;
}
.fw-500 {
	font-weight: 500;
}
.fw-600 {
	font-weight: 600;
}
.measurements-wrap .card-body {
	min-height: 720px;
}
.measurement-title {
	font-size: 1.2rem;
	font-weight: 600;
	text-align: center;
}
.measurement-value {
	font-size: 1.4rem;
}
.hip-img-top {
	width: 65%;
	margin: 0.9rem 0;
}
.hip-img-lower {
	width: 40%;
}
.hip-img-left {
	margin-left: 1.5rem !important;
}
.hip-img-right {
	margin-right: 1.5rem !important;
}
.tha-measurement-index {
    padding-left: 5px;
    font-weight: 500;
    font-size: 0.8rem;
}
.text-italic {
    font-style: italic;
    font-size: 0.7rem;
}
.index-legend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
.index-legend.legend-app { background: #c86ca6; }
.index-legend.legend-fpp { background: #73bc51; }
/* New Settings Page Styles Start */
.w-40 {
	min-width: 40%;
}
.account-tab-contents {
	background: #fff;
	padding: 20px 15px;
	border-radius: 5px;
}
.account-tab-contents .nav-link {
	color: #8A8A8A;
	background: transparent !important;
	border-radius: 0 !important;
	padding: 5px 10px;
	margin: 5px 0;
	font-weight: 600;
	border-left: 3px solid transparent;
	cursor: pointer;
}
.account-tab-contents .nav-link.active {
	color: #000 !important;
	border-color: #73bc51;
}
.tfa-info {
	color: #888;
	font-size: 0.9rem;
	margin-top: 15px;
}
.account-tabs-list {
	border-right: 1px solid #efefef;
}
/* New Settings Page Styles End */
/* Screenshots Tab Styles Start */
img.thumb-img {
	width: 100%;
	cursor: pointer;
}
.screenshots-wrap {
	border: 1px solid #fff;
	background: #fff;
	border-radius: 6px;
	padding: 10px 15px 20px;
}
/* Screenshots Tab Styles End */
/* Admin device list start */
.device-fields .react-dropdown-select-dropdown {
	width: 100% !important;
	position: absolute !important;
	top: 43px !important;
}
.device-fields .react-dropdown-select-dropdown-handle {
	display: flex;
	align-items: center;
}
.device-fields .react-dropdown-select-input {
	font-size: inherit !important;
}
/* Admin device list end */
.license-expiry-list {
	width: 100%;
}
.overlapping-points-modal {
	min-width: 700px !important;
}
.btn-sm-primary {
	background-color: #73BC51 !important;
	color: #ffffff !important;
	border: 0 !important;
	padding: 5px 10px !important;
	font-size: 0.8rem;
}
.overlapping-cases tr>td,
.overlapping-cases tr>th {
	padding: 5px 0;
	vertical-align: middle;
}
.add-user-password-input .add-user-password-error-msg {
	font-size: 0.8rem !important;
}
.add-user-password-input .add-user-password-error-msg,
.add-user-password-input .hint-block,
.add-user-password-input .progress-bar,
.new-password .reset-password-error-msg,
.new-password .hint-block,
.new-password .progress-bar,
.new-password-errors { font-size: 0.8rem !important; }
/* Password Hint Popup Start*/
.password-hint-popup {
	position: absolute;
	width: 100%;
}
.password-hint-popup .password-hints-list {
	list-style: square !important;
	padding-left: 15px !important;
}
/* Password Hint Popup End*/
.btn-surgeon-unique-id {
	padding: 0;
	color: #414042;
	font-size: .8rem;
}
.btn-surgeon-unique-id:hover, 
.btn-surgeon-unique-id:active {
	color: #000;
}

/* Bill of materials */
.bill-of-materials { width: 100%; }
.bom-modal { min-height: 340px; }
.bom-modal .loading-overlay {
	left: 0;
	height: 100%;
}

.border-lr {
	border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
}
.planning-tool-error { text-align: left !important; }
.planning-review-tool .range-wrapper { margin-top: 5px !important; margin-bottom: 10px !important;}
.planning-review-tool .range-thumb-bubble { padding: 3px 8px; }
.case-count-text {
    margin: 0;
    font-weight: 500;
}
.comments-history-dialog { max-width: 560px !important; }
.comments-history-dialog .modal-body { min-height: 500px; }
.comments-history-dialog .table-contents {
	max-height: 500px;
	overflow-y: scroll;
}
.comments-history-dialog table.comment-history { width: 100% !important; }
.planning-review-tool .patient-sex-wrap { height: 31px; }
.planning-review-tool .tensioner-force-wrap .form-check-label {
    font-size: 0.9rem;
    vertical-align: text-bottom;
}
.customer-device-list .table-contents { margin: 0 !important; }
.shrink, .customer-fields .case-details-container {
	animation: all 0.5s;
}

.components-body.manual .card-body .row { margin-top: 6%; }
.components-body.tensioner .card-body .row { margin-top: 8%; }
.downtime-error { text-align: left !important; margin-bottom: 5px; }
.downtime-banner p { font-weight: 600; }

/* File Upload Page Updates */
.file-form-wrap { padding: 15px; }
.file-upload-header { 
	font-size: 18px;
	margin: 0;
}
.multiselect-wrap { border: 1px solid transparent; border-radius: 5px; }

/* Text Editor Styles */
.editor-wrapper { border: 1px solid #ced4da; border-radius: 5px; min-height: 156px; }
.editor-toolbar { border-radius: 5px !important; }
.editor-body { padding: 0 15px; max-height: 200px; }
.DraftEditor-editorContainer { z-index: 0 !important; }
.rdw-dropdown-selectedtext, .rdw-dropdown-selectedtext:hover,
.rdw-dropdown-selectedtext:active, .rdw-dropdown-selectedtext:visited {
	text-decoration: none !important;
	color: inherit;
}

.search-dropdown { width: 230px; margin-right: 15px; }
.file-search { width: 350px; }
.desc-block { height: 160px; overflow: auto; }
.disabled-toolbar {
    pointer-events: none;
    opacity: 0.5;
}

.logger-filter-input-wrap {
	display: flex;
	flex-direction: column;
	width: 19%;
}

/* FILE DETAILS/EDIT */
.disabled, 
.file-details-container .form-control:disabled,
.file-details-container .form-select:disabled { 
	background-color: #f7f7f7; 
	border: 1px solid #e4e4e4; 
}
.slider.disabled { opacity: 0.7; }
.da-edit-icon {
    display: flex;
    margin-left: 15px;
    cursor: pointer;
    transition: all 0.5s;
}
.btn { transition: all 0.5s; }
.btn-link:hover { color: #000 !important }
.da-edit-icon { color: #73bc51 }
.da-edit-icon:hover { color: #000 }
.file-details-container .form-control,
.file-details-container .form-select {
	background-color: #fff;
	border: 1px solid #e4e4e4;
	border-radius: 3px;
	font-size: 12px;
}

.da-search-wrap { display: flex; max-width: 81%; }
.region-container textarea.form-control { resize: none; }

/* ============================ */
/* ===== RESPONSIVE RULES ===== */
/* ============================ */
@media (resolution: 1.5dppx) {
	body, table, a, label, button {
		font-size: 0.8rem;
	}
	.form-select, input.form-control, textarea.form-control { font-size: 0.8rem !important; }
	.loading-overlay-upload {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		background: #0e0e0e;
		opacity: 0.3;
		color: #ffffff;
		display: table;
		height: 100%;
		z-index: 3;
	}
	.loading-overlay-upload span {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		padding-top: 20px;
	}
	.align-image-text {
		margin: auto;
	}
	.case-text-align-below {
		margin-left: -10px !important;
	}
	.color-orange-right {
		margin-left: 0px !important;
		margin-bottom: 10px;
		padding-right: 0;
	}
	.color-purple-left {
		margin-left: -8px !important;
		margin-right: -8px;
		position: relative;
	}
	.color-purple-right {
		margin-left: 0px !important;
		margin-top: -9px !important;
	}
	.img-bg-color {
		width: 85px !important;
		height: 140px !important;
	}
	.img-bg-color-preop {
		margin-top: -5px !important;
		width: 120px !important;
	}
	.case-component-div .align-image-text .align-text-middle,
	.case-component-div .align-image-text .align-text-middle-single-point {
		min-width: 38px;
	}
	.rar-surgeon-list-block .rt-tbody {
		width: 54.75% !important;
	}
	.rar-surgeon-list-block th:nth-child(1),
	.rar-surgeon-list-block th:nth-child(2) {
		width: 3% !important;
	}
	.planning-review-tool .case-component-div span.unit {
		font-size: 8px !important;
	}
	.planning-review-tool .case-component-div span.value {
		font-size: 14px !important;
	}
	.dashboard-container .tab-content {
		font-size: 12px !important;
	}
	.case-list-filter ul.filter-options>li {
		width: 31% !important;
	}
	.surgeon-customer-select {
		flex-direction: column;
	}
	.surgeon-customer-select .card-body {
		max-width: 100%;
	}
	.review-case-details-wrap .editable-fields .error-msg-block {
		font-size: 0.7rem !important;
	}
	table.review-case-list th,
	table.review-case-list td:nth-child(even) {
		width: 9% !important;
	}
	.surgeon-search-results th:nth-child(1) {
		width: 24% !important;
	}
	.surgeon-search-results th:nth-child(2) {
		width: 15% !important;
	}
	.surgeon-search-results th:nth-child(3) {
		width: 18% !important;
	}
	.surgeon-search-results th:nth-child(4) {
		width: 28% !important;
	}
	.customer-fields label {
		padding-right: 0 !important;
	}
	.planning-review-tool .case-component-div .align-text-middle,
	.planning-review-tool .case-component-div .align-text-middle-single-point {
		margin: 0 !important;
	}
	.case-details-container .form-control,
	.case-details-container .form-select {
		padding-left: 5px;
		font-size: 11px;
	}
	.case-details-container .col-form-label {
		font-size: 11px;
		padding-right: 0;
		margin-top: 0 !important;
	}
	.flexion-range-margin {
		margin-left: 0;
	}
	.flexion-range-input {
		width: 50px;
	}
	.card-body .flexion-range {
		margin-left: -25px;
	}
	.rar-request-list-table td:nth-child(1) {
		width: 20.5% !important;
	}
	.app-logs table {
		height: 69vh !important;
	}
	.faq-block {
		max-height: 73vh;
	}
	.logs-export-btn {
		margin-left: 30%;
		margin-top: 2.5%;
	}
	.surgeon-details .shrink-label {
		/* padding: 7px 0 !important; */
		font-size: 0.7rem;
	}
	.case-details-na {
		top: 95px;
	}
	.export-btn .error-block {
		font-size: 14px;
	}
	.measurement-title {
		font-size: 0.8rem !important;
	}
	.measurement-value {
		font-size: 0.9rem !important;
	}
	.hip-img-left {
		margin-left: 1rem !important;
	}
	.hip-img-right {
		margin-right: 1rem !important;
	}
	.reset-form.w-40 {
		min-width: 71% !important;
	}
	.reset-password ul,
	.new-password-errors {
		font-size: 0.7rem !important;
	}
	.add-user-password-input .add-user-password-error-msg,
	.add-user-password-input .hint-block,
	.add-user-password-input .progress-bar,
	.new-password .reset-password-error-msg,
	.new-password .hint-block,
	.new-password .progress-bar {
		font-size: 0.6rem !important;
	}
	.quadrant-tabs .nav-item { margin-left: 1px !important; }
	.case-details-heading { font-size: 18px; }
	.case-details-container .card-title,.case-details-gaps-title { font-size: 0.8rem; }
	.app-logs .pagination { width: 100%; }
	ul.password-hints-list > li {
		font-size: 0.7rem !important;
	}
	.last-updated-text { font-size: 10px; }
	.range-thumb-bubble {
		font-size: 12px;
    	padding: 2px 5px;
	}
	.planning-review-tool .range-extreme-label-min,
	.planning-review-tool .range-extreme-label-max {
		top: -10px;
    	font-size: 12px;
	}
	.planning-review-tool .tensioner-force-wrap .form-check-label {
		font-size: 0.7rem;
	}
	.components-body.manual .card-body {
		min-height: 528px;
	}
	.components-body.manual .card-body .row {
		margin-top: 15%;
	}
	.components-body.tensioner .card-body .row {
		margin-top: 17%;
	}
	.desc-block { height: 168px; }
}
@media (resolution: 1.25dppx) {
	body, table, a, label, button, select, input {
		font-size: 0.8rem;
	}
	.form-select, input.form-control { font-size: 0.8rem !important; }
	.align-image-text {
		margin: auto;
	}
	.case-text-align-below {
		margin-left: -20px !important;
	}
	.color-orange-left {
		margin-left: -24px !important;
	}
	.color-orange-right {
		margin-left: 0px !important;
	}
	.color-purple-right {
		margin-left: 0px !important;
	}
	.img-bg-color {
		width: 100px !important;
		height: 165px !important;
	}
	.img-bg-color-preop {
		margin-top: -35px !important;
		width: 140px !important;
	}
	.file-upload {
		margin-left: 11% !important;
	}
	.datalist-style {
		left: 19% !important;
		width: 80% !important;
	}
	.data-list-tabs,
	.case-details-wrap {
		margin-left: 150px !important;
	}
	.rar-dashboard-top {
		margin-left: 11% !important;
	}
	.setting-header {
		margin-left: 11% !important;
	}
	.rar-surgeon-list-block .rt-tbody {
		width: 57.5% !important;
	}
	.rar-surgeon-list-block th:nth-child(1),
	.rar-surgeon-list-block th:nth-child(2) {
		width: 3% !important;
	}
	.case-list-filter ul.filter-options>li {
		width: 31% !important;
	}
	.surgeon-customer-select {
		flex-direction: column !important;
	}
	.surgeon-customer-select .card-body {
		max-width: 100% !important;
	}
	.editable-fields .card-body .card-title {
		margin-bottom: 0;
		padding-bottom: 8px
	}
	button#save-details {
		padding: 5px 20px !important;
	}
	.surgeon-customer-select .form-group {
		margin-bottom: 0.5rem;
	}
	.review-case-details-wrap .editable-fields label {
		padding-bottom: 0px;
		padding-top: 3px;
	}
	.review-case-details-wrap .editable-fields .error-msg-block {
		font-size: 0.85rem !important;
	}
	.planning-review-tool .case-component-div {
		min-height: 400px;
	}
	table.review-case-list th,
	table.review-case-list td:nth-child(8) {
		width: 7% !important;
	}
	.surgeon-search-results th:nth-child(1) {
		width: 22% !important;
	}
	.surgeon-search-results th:nth-child(2) {
		width: 20% !important;
	}
	.surgeon-search-results th:nth-child(3) {
		width: 13% !important;
	}
	.surgeon-search-results th:nth-child(4) {
		width: 34% !important;
	}
	.flexion-range-margin {
		margin-left: 15px;
	}
	.rar-request-list-table td:nth-child(1) {
		width: 18% !important;
	}
	.app-logs table {
		height: 75vh !important;
	}
	.faq-block {
		max-height: 78vh;
	}
	.case-details-na {
		top: 108px;
	}
	.measurement-title {
		font-size: 0.8rem !important;
	}
	.measurement-value {
		font-size: 0.85rem !important;
	}
	.mid-section .measurement-title {
		text-align: left;
	}
	.hip-img-left {
		margin-left: 1rem !important;
	}
	.hip-img-right {
		margin-right: 1rem !important;
	}
	.reset-form.w-40 {
		min-width: 63% !important;
	}
	.add-user-password-input .add-user-password-error-msg,
	.add-user-password-input .hint-block,
	.add-user-password-input .progress-bar,
	.new-password .reset-password-error-msg,
	.new-password .hint-block,
	.new-password .progress-bar {
		font-size: 0.7rem !important;
	}
	ul.password-hints-list > li {
		font-size: 0.7rem !important;
	}
	.range-thumb-bubble {
		font-size: 12px;
    	padding: 2px 5px;
	}
	.planning-review-tool .tensioner-force-wrap .form-check-label {
		font-size: 0.7rem;
		margin-right: -5px;
	}
	.components-body .card-body {
		min-height: 575px;
	}
	.components-body.manual .card-body {
		min-height: 529px;
	}
}
@media (resolution: 1dppx) {
	.loading-overlay-upload {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		background: #0e0e0e;
		opacity: 0.3;
		color: #ffffff;
		display: table;
		height: 100%;
		z-index: 3;
	}
	.loading-overlay-upload span {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		padding-top: 20px;
	}
	.align-image-text {
		margin: auto;
		margin-left: 12% !important;
	}
	.case-text-align-below {
		font-size: 14px !important;
	}
	.color-orange-left {
		margin-left: -40px !important;
		font-size: 14px !important;
	}
	.color-orange-right {
		margin-left: 15px !important;
		font-size: 14px !important;
	}
	.color-purple-left {
		margin-left: -40px !important;
		font-size: 14px !important;
	}
	.color-purple-right {
		margin-left: 10px !important;
		font-size: 14px !important;
	}
	.img-bg-color {
		width: 80px !important;
		height: 160px !important;
	}
	.img-bg-color-preop {
		margin-top: -35px !important;
		width: 140px !important;
	}
	.file-upload {
		margin-left: 7% !important;
	}
	.datalist-style {
		left: 15.5% !important;
		width: 84% !important;
	}
	.data-list-tabs,
	.case-details-wrap {
		margin-left: 123px !important;
	}
	.rar-dashboard-top {
		margin-left: 7% !important;
	}
	.setting-header {
		margin-left: 7% !important;
	}
	.planning-review-tool .case-component-div .align-image-text .align-text-middle,
	.planning-review-tool .case-component-div .align-image-text .align-text-middle-single-point {
		min-width: 70px;
	}
	table.review-case-list th,
	table.review-case-list td:nth-child(8) {
		width: 10% !important;
	}
	.logs-export-btn {
		margin-left: 42%;
		margin-top: 1.7%;
	}
}
@media screen and (max-width: 1440px) {
	.setting-header {
		margin-left: 11.5% !important;
	}
	.data-list-tabs,
	.case-details-wrap,
	.notifications-page {
		margin-left: 150px !important;
	}
	.file-upload {
		margin-left: 14% !important;
	}
	.datalist-style {
		left: 21.5% !important;
		width: 77% !important;
	}
	.surgeon-customer-select {
		flex-direction: column;
	}
	.surgeon-customer-select .card-body {
		max-width: 100%;
	}
	.editable-fields .card-body .card-title {
		margin-bottom: 0;
	}
	button#save-details {
		padding: 5px 20px !important;
	}
	.surgeon-customer-select .form-group {
		margin-bottom: 0.5rem;
	}
	.review-case-details-wrap .editable-fields label {
		padding-bottom: 0px;
		padding-top: 3px;
	}
	.review-case-details-wrap .editable-fields .error-msg-block {
		font-size: 0.75rem !important;
	}
	table.review-case-list th,
	table.review-case-list td:nth-child(even) {
		width: 9% !important;
	}
	.flexion-range-margin {
		margin-left: 0;
	}
	.flexion-range-input {
		width: 50px;
	}
	.logs-export-btn {
		margin-left: 35%;
		margin-top: 29px;
	}
	.case-component-div .align-image-text {
		margin-left: 16% !important;
	}
	.case-component-div .align-image-text .align-text-middle,
	.case-component-div .align-image-text .align-text-middle-single-point {
		min-width: 30px;
	}
	.case-component-div .align-text-middle-single-point .color-purple-right {
		margin-left: 0 !important;
	}
	.planning-review-tool .case-component-div .align-image-text,
	.planning-review-tool .case-component-div .align-text-middle .color-orange-left,
	.planning-review-tool .case-component-div .align-text-middle-single-point .color-orange-left {
		margin-left: 0 !important;
	}
	.surgeon-details .shrink-label {
		/* padding: 7px 0 !important; */
		font-size: 0.7rem;
	}
	.case-details-na {
		top: 100px;
	}
	.measurement-title {
		font-size: 0.8rem;
	}
	.measurement-value {
		font-size: 0.9rem;
	}
	.hip-img-left {
		margin-left: 1rem !important;
	}
	.hip-img-right {
		margin-right: 1rem !important;
	}
	.mid-section .measurement-title {
		text-align: left;
	}
	.last-updated-text { font-size: 10px; }
	.range-thumb-bubble {
		font-size: 12px;
    	padding: 2px 5px;
	}
	.planning-review-tool .range-extreme-label-min,
	.planning-review-tool .range-extreme-label-max {
		top: -10px;
    	font-size: 12px;
	}
	.planning-review-tool .tensioner-force-wrap .form-check-label {
		font-size: 0.7rem;
	}
	.desc-block { height: 168px; }
	@media (resolution: 1.5dppx) {
		.setting-header {
			margin-left: 14% !important;
		}
		.data-list-tabs,
		.case-details-wrap {
			margin-left: 160px !important;
		}
		.file-upload {
			margin-left: 14.5% !important;
		}
		.datalist-style {
			left: 22.5% !important;
			width: 76.5% !important;
		}
	}
	@media (resolution: 1dppx) {
		.surgeon-search-results th:nth-child(2),
		.surgeon-search-results th:nth-child(3) {
			width: 17% !important;
		}
		.reset-form.w-40 {
			min-width: 71% !important;
		}
		.reset-password ul {
			font-size: 0.7rem;
		}
		.add-user-password-input .add-user-password-error-msg,
		.add-user-password-input .hint-block,
		.add-user-password-input .progress-bar,
		.new-password .reset-password-error-msg,
		.new-password .hint-block,
		.new-password .progress-bar {
			font-size: 0.6rem !important;
		}
		.rar-surgeon-list-block .rt-tbody {
			width: 56.75% !important;
		}
		.quadrant-tabs .nav-item { margin-left: 1px !important; }
		.app-logs .table-contents { height: 89%; }
		.app-logs .pagination { width: 100%; }
		ul.password-hints-list > li {
			font-size: 0.7rem !important;
		}
		.form-select, input.form-control { font-size: 0.8rem !important; }
	}
}
@media screen and (max-width: 1366px) {
	.components-body .card-body {
		min-height: 574px;
	}
	.components-body.manual .card-body {
		min-height: 528px;
	}
	.measurements-wrap .card-body {
		min-height: 715px;
	}
	.login-logo img {
		height: 54px;
	}
}
@media screen and (min-width: 1920px) {
	.planning-review-tool .img-bg-color {
		min-width: 200px;
		min-height: 300px;
	}
	.planning-review-tool .case-component-div {
		min-height: 520px;
	}
}